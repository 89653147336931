<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col-12
      om-heading.mt-1(h1) {{ $t('changeEmail.address') }}
    .col-12.mt-5.font-size-0--875 {{ $t('changeEmail.description') }}
    .col-12.d-flex
      om-toast.change-email-info.mt-5(v-if="submitted")
        div(v-html="$t('changeEmail.alert', { email })")
  .row
    .col-5.col-lg-3.mt-1.position-static
      form(submit.prevent="verify()" ref="form")
        .form-group.row
          .col-12
            om-input#changeEmail(
              v-model="email"
              type="email"
              :placeholder="$t('changeEmail.newEmail')"
              :label="$t('changeEmail.newEmail')"
            )
              template(slot="error" v-if="$v.email.$error && !$v.email.required")
                span {{ $t('requiredField') }}
              template(slot="error" v-if="$v.email.$error && !$v.email.isCoolEmail")
                span {{ $t('invalidEmail') }}
              template(slot="error" v-if="hasEmailError")
                span {{ $t('changeEmail.emailAlreadyExists') }}
        .form-group.row
          .col-12
            om-input#changeEmailConfirm(
              v-model="emailConfirm"
              type="email"
              :placeholder="$t('changeEmail.newEmail')"
              :label="$t('changeEmail.newEmailConfirm')"
            )
              template(slot="error" v-if="$v.emailConfirm.$error && !$v.emailConfirm.required")
                span {{ $t('requiredField') }}
              template(slot="error" v-if="$v.emailConfirm.$error && !$v.emailConfirm.isCool")
                span {{ $t('emailMismatch') }}
        .form-group.row
          .col-12.mt-2
            om-button#changeEmailVerifyBtn(primary @click.prevent="verify()") {{ $t('save') }}
</template>

<script>
  import CHANGE_EMAIL from '@/graphql/ChangeEmail.gql';
  import { ERROR_MESSAGE_KEY } from '@/utils/constant';
  import { required, email } from 'vuelidate/lib/validators';
  import { keyInErrors } from '@/utils/error';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        originalEmail: '',
        email: '',
        emailConfirm: '',
        submitted: false,
        hasEmailError: false,
      };
    },

    computed: {
      ...mapGetters(['getAccountEmail']),
    },
    validations: {
      email: {
        required,
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
      },
      emailConfirm: {
        required,
        isCool(value) {
          if (!value) {
            return true;
          }
          return value === this.email;
        },
      },
    },
    methods: {
      resetValues() {
        this.email = '';
        this.emailConfirm = '';
      },
      async verify() {
        this.hasEmailError = false;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
        } else {
          this.$v.$reset();
          try {
            this.originalEmail = this.getAccountEmail;
            const result = await this.$apollo.mutate({
              mutation: CHANGE_EMAIL,
              variables: {
                email: this.email.toLowerCase(),
              },
            });
            const { errors } = result;
            if (keyInErrors(ERROR_MESSAGE_KEY.EMAIL_ALREADY_REGISTERED, errors)) {
              this.hasEmailError = true;
              this.$notify({
                type: 'error',
                text: this.$t('changeEmail.emailAlreadyExists'),
              });
            } else if (keyInErrors(ERROR_MESSAGE_KEY.RATE_LIMIT, errors)) {
              this.$notify({
                type: 'error',
                text: this.$t('changeEmail.tryAgainLater'),
              });
            } else if (errors?.length) {
              this.this.$notify({
                type: 'error',
                text: this.$t('changeEmail.saveError'),
              });
            } else {
              this.submitted = true;
              this.hasEmailError = false;
              this.$notify({
                type: 'success',
                text: this.$t('notifications.sendChangeEmail', { email: this.originalEmail }),
              });
              this.$refs.form.reset();
              this.resetValues();
            }
          } catch (e) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.saveError'),
            });
          }
        }
      },
    },
  };
</script>
<style lang="sass">
  .change-email-info
    max-width: 520px
</style>
